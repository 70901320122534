import themePalette from "../../../../.brew-cache/theme.json";

import buttonArrow from "../../../images/button-arrow.png";
import buttonArrowWhite from "../../../images/button-arrow-white.png";

export default (theme) => ({
  root: {
    "& button": {
      fontFamily: theme.headerFont,
      padding: "16px 30px",
      boxShadow: "0 8px 10px rgba(0,0,0,0.08)",
      fontSize: 18,
      lineHeight: 1.25,
      fontWeight: 800,
      letterSpacing: "0.008em",
      textAlign: "center",
      borderRadius: 26,
      textTransform: "none",
      "&::after": {
        width: 20,
        height: 17,
        content: '""',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + buttonArrow + ")",
        display: "inline-block",
        marginLeft: 20,
        backgroundSize: "contain",
      },
      "&.no-icon::after": {
        display: "none",
      },
      // Foreground colours
      '&[data-text-color="primary"]': {
        color: themePalette.ThemeOptions.mainColours.primaryColour,
      },
      '&[data-text-color="secondary"]': {
        color: themePalette.ThemeOptions.mainColours.secondaryColour,
      },
      '&[data-text-color="tertiary"]': {
        color: themePalette.ThemeOptions.mainColours.tertiaryColour,
      },
      '&[data-text-color="foreground_primary"]': {
        color:
          themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour,
      },
      '&[data-text-color="foreground_secondary"]': {
        color:
          themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour,
      },
      '&[data-text-color="foreground_tertiary"]': {
        color:
          themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour,
      },
      '&[data-text-color="background_primary"]': {
        color:
          themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour,
      },
      '&[data-text-color="background_secondary"]': {
        color:
          themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour,
      },
      '&[data-text-color="background_tertiary"]': {
        color:
          themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour,
      },
      // Background colours
      '&[data-bg-color="primary"]': {
        backgroundColor: themePalette.ThemeOptions.mainColours.primaryColour,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.dark,
        },
        "&::after": {
          backgroundImage: "url(" + buttonArrowWhite + ")",
        },
      },
      '&[data-bg-color="secondary"]': {
        backgroundColor: themePalette.ThemeOptions.mainColours.secondaryColour,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.secondary.dark,
        },
        "&::after": {
          backgroundImage: "url(" + buttonArrowWhite + ")",
        },
      },
      '&[data-bg-color="tertiary"]': {
        backgroundColor: themePalette.ThemeOptions.mainColours.tertiaryColour,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.tertiary.dark,
        },
      },
      '&[data-bg-color="foreground_primary"]': {
        backgroundColor:
          themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour,
      },
      '&[data-bg-color="foreground_secondary"]': {
        backgroundColor:
          themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour,
      },
      '&[data-bg-color="foreground_tertiary"]': {
        backgroundColor:
          themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour,
      },
      '&[data-bg-color="background_primary"]': {
        backgroundColor:
          themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour,
      },
      '&[data-bg-color="background_secondary"]': {
        backgroundColor:
          themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour,
      },
      '&[data-bg-color="background_tertiary"]': {
        backgroundColor:
          themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour,
      },
    },
  },
  buttonTheme: {
    ...theme.buttonTheme,
  },
});
